<template>
  <div class="page px-4 px-md-4 pt-4">
    <div class="d-flex px-0 px-md-4 pb-8 align-center justify-space-between">
      <span class="font-weight-bold">
        Forum Member
      </span>
      <span class="caption-small">
        Home > Forum Member
      </span>
    </div>
    <v-row no-gutters>
      <v-col col="4" class="col-md-2 pl-1 pl-md-4">
        <v-btn :disabled="tabActive === 'all'" depressed block color="blue" small class="caption-small text-capitalize" @click.prevent="newPost.show = false, ShowMedia('all')">
          All Post
        </v-btn>
      </v-col>
      <v-col col="4" class="col-md-2 px-2 px-md-4">
        <v-btn :disabled="tabActive === 'featured'" depressed block color="blue" small class="caption-small text-capitalize" @click.prevent="newPost.show = false, ShowMedia('featured')">
          Editor Pick
        </v-btn>
      </v-col>
      <v-col col="4" class="col-md-2 pr-1 pr-md-4">
        <v-btn :disabled="tabActive === 'archive'" depressed block color="orange darken-1" small class="caption-small text-capitalize" @click.prevent="newPost.show = false, ShowMedia('archive')">
          Archive
        </v-btn>
      </v-col>
      <v-col cols="12" class="px-1 px-md-4 py-4">
        <v-text-field
          v-model="search"
          label="Search Post"
          outlined
          block
          dense
          :color="$vuetify.theme.dark ? 'grey' : 'primary'"
          class="accent"
          append-icon="mdi-magnify"
          hide-details
          autocomplete="off"
        />
      </v-col>
      <v-col v-if="tabActive !== 'archive'" col="12" class="px-1 px-md-4 pb-4">
        <v-btn depressed color="green" block class="text-capitalize" @click.prevent="newPost.show ? newPost.show = false : (ShowMedia('all'), newPost.show = true)">
          <v-icon small class="mr-2">
            {{ newPost.show ? 'mdi-close' : 'mdi-plus-circle-outline' }}
          </v-icon>
          <span class="caption-small">
            Add New Post
          </span>
        </v-btn>
      </v-col>
      <v-col v-if="newPost.show" cols="12" class="px-1 px-md-4 mb-4">
        <v-card class="accent mb-2 rounded-lg no-shadow" style="overflow: hidden;">
          <v-text-field
            v-model="newPost.title"
            :name="Math.random()"
            autocomplete="off"
            label="Type post title"
            dense
            hide-details
            solo
            color="accent"
            class="pa-2 rounded-0 no-shadow"
          />
          <EbrightEditor id="newPost" :content="newPost.text" @save="SaveNewPost" />
          <v-row
            v-if="newPost.show"
            no-gutters
            align="center"
            justify="end"
            class="pa-2"
          >
            <v-col cols="6">
              <v-btn
                text
                small
                color="grey"
                class="text-capitalize grey--text mr-1"
                @click.prevent="UPLOAD_FILE('attach', 0, true)"
              >
                <v-icon class="mr-2 caption">
                  mdi-attachment
                </v-icon>
                <span class="caption-small">
                  Attach file
                </span>
              </v-btn>
              <v-btn
                v-for="(c, iC) in newPost.cover"
                :key="'cover-' + iC"
                outlined
                small
                color="grey"
                class="text-capitalize grey--text px-4 mr-1"
                :href="c"
                target="_blank"
              >
                <span class="caption-small">
                  File {{ (iC + 1) }}
                </span>
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn :disabled="!newPost.text" depressed x-small color="green" class="text-capitalize white--text px-4" @click.prevent="CreateNewPost()">
                Create
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" class="mb-4">
        <v-card-text class="pt-0 px-1 px-md-4">
          <v-card
            v-if="!forum.data.length"
            class="text-center pa-4 accent"
            :dark="$vuetify.theme.dark"
          >
            {{ loading ? 'loading post ...' : 'No Result' }}
          </v-card>
          <v-card
            v-for="(f, iF) in forum.data"
            :key="'forum-' + iF"
            outlined
            v-observe-visibility="Observe"
            :observe="f.id"
            :hasread="f.hasread"
            class="no-shadow mb-4 accent rounded-lg"
            :dark="$vuetify.theme.dark"
          >
            <v-card-title>
              <v-avatar size="30" class="mr-2">
                <img
                  alt="user"
                  :src="f.img || '/icon.png'"
                >
              </v-avatar>
              <div>
                <div class="caption font-weight-bold pb-1" style="line-height: 100%;">
                  {{ f.creator_name }}
                </div>
                <div class="caption-small pt-1 text-capitalize" style="line-height: 100%;">
                  {{ f.creator_role }}
                </div>
              </div>
              <v-spacer />
              <v-btn v-if="parseInt($store.state.user.id) === parseInt(f.user)" :color="$vuetify.theme.dark ? 'grey lighten-2' : 'grey lighten-2'" fab x-small class="no-shadow mr-2" @click.prevent="UpdatePost(f)">
                <v-icon v-if="!f.content_edit.show" color="grey">mdi-pencil</v-icon>
                <v-icon v-else color="green">mdi-check-bold</v-icon>
              </v-btn>
              <v-btn v-if="$store.state.user.role === 'pusat'" :color="$vuetify.theme.dark ? 'grey lighten-2' : 'grey lighten-2'" fab x-small class="no-shadow" @click.prevent="SetFeatured(f)">
                <v-icon v-if="parseInt(f.featured) === 1" color="orange">mdi-star</v-icon>
                <v-icon v-else color="grey">mdi-star-outline</v-icon>
              </v-btn>
              <v-icon v-else-if="parseInt(f.featured) === 1">mdi-star</v-icon>
            </v-card-title>

            <div class="px-4 caption-small grey--text">
              {{ $localDT(f.created, 'datetime') }}
            </div>

            <v-card-text class="pt-0 pb-4 px-4">
              <v-text-field
                v-if="forum.data[iF].content_edit.show"
                v-model="forum.data[iF].content_edit.title"
                :name="Math.random()"
                autocomplete="off"
                label="Post title"
                dense
                hide-details
                outlined
                class="accent pb-2 mb-2"
              />
              <div v-else class="mb-1 text-capitalize font-weight-bold">
                {{ forum.data[iF].content_edit.title }}
              </div>
              <div v-if="!forum.data[iF].content_edit.show" class="ebright-post-content" style="line-height: 130%;" v-html="$stringToEmoticon($EditorParse(f.content), $store.state.emoticons.emoticons)" />
              <EbrightEditor v-else :id="'ebright-post-content-' + iF" :content="forum.data[iF].content_edit.content" add-class="comment-field" @save="forum.data[iF].content_edit.content = $event" />
            </v-card-text>
            <div class="d-flex justify-space-between px-4 pb-4">
              <div>
                <v-btn v-if="parseInt(f.status) === 1" :color="$vuetify.theme.dark ? 'black--text grey lighten-2' : 'white--text primary'" depressed x-small class="mr-2" @click.prevent="forum.data[iF].new_comment_show = !forum.data[iF].new_comment_show, f.comments.length ? forum.data[iF].comments = [] : LoadComment(f.id, iF)">
                  <v-icon x-small :class="[parseInt(f.comments_total) ? 'mr-2' : '']">
                    mdi-comment-outline
                  </v-icon>
                  <span v-if="parseInt(f.comments_total)" class="caption-small" style="line-height: 100%;">
                    {{ f.comments_total }}
                  </span>
                </v-btn>
                <v-btn v-if="parseInt(f.status) === 1 && $store.state.user.role === 'pusat'" color="orange black--text" class="px-0" style="min-width: 20px;" depressed x-small @click.prevent="removePost(f.id, iF)">
                  <v-icon small>
                    mdi-archive-outline
                  </v-icon>
                </v-btn>
                <v-btn v-else-if="parseInt(f.status) === 99 && $store.state.user.role === 'pusat'" color="green black--text" class="px-0" style="min-width: 20px;" depressed x-small @click.prevent="unremovePost(f.id, iF)">
                  <v-icon small>
                    mdi-archive-outline
                  </v-icon>
                </v-btn>
                <v-btn v-if="$store.state.user.role === 'pusat'" color="red black--text" class="ml-2 px-0" style="min-width: 20px;" depressed x-small @click.prevent="DeletePost(f.id, iF)">
                  <v-icon small>
                    mdi-delete-outline
                  </v-icon>
                </v-btn>
              </div>

              <div>
                <v-btn
                  v-for="(c, iC) in $StrToJson(forum.data[iF].cover || '[]')"
                  :key="'cover-attach-' + iC"
                  text
                  small
                  color="grey"
                  class="text-capitalize grey--text"
                  :href="c"
                  target="_blank"
                >
                  <span class="caption-small">
                    File {{ (iC + 1) }}
                  </span>
                </v-btn>
                <v-btn
                  v-if="forum.data[iF].content_edit.show"
                  text
                  small
                  class="text-capitalize grey--text ml-1"
                  @click.prevent="UPLOAD_FILE('attach', iF, 'post')"
                >
                  <span class="caption-small">
                    Attach file
                  </span>
                </v-btn>
                <v-icon v-if="forum.data[iF].content_edit.show || $StrToJson(forum.data[iF].cover || '[]').length" class="green--text caption">
                  mdi-attachment
                </v-icon>
              </div>
            </div>
            <v-card-text v-if="forum.data[iF].new_comment_show && parseInt(f.status) === 1" class="pt-0 px-4">
              <EbrightEditor :id="'new-comment-' + iF" :content="forum.data[iF].new_comment" add-class="comment-field" @save="forum.data[iF].new_comment = $event" />
              <v-row
                align="center"
                justify="end"
                class="pt-4"
                no-gutters
              >
                <v-col cols="6">
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-btn :disabled="!forum.data[iF].new_comment" depressed x-small :color="$vuetify.theme.dark ? 'black--text grey lighten-2' : 'white--text primary'" class="text-capitalize" @click.prevent="SendComment(iF)">
                    <v-icon class="mr-1 body-1">
                      mdi-reply
                    </v-icon>
                    <span>
                      Reply
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text v-if="parseInt(f.comments_total) && f.comments.length && parseInt(f.status) === 1" class="pt-0 px-4 px-md-4">
              <div class="rounded" style="max-height: 750px;overflow-y: auto;">
                <v-card v-for="(fc, iFc) in f.comments" :key="'comment-' + iF + '-' + iFc" class="accent mb-4 no-shadow">
                  <v-card-text class="caption d-flex pb-0 pt-4">
                    <v-avatar size="25" class="mr-2">
                      <img
                        alt="user"
                        :src="fc.img || '/icon.png'"
                      >
                    </v-avatar>
                    <div>
                      <div class="caption-small font-weight-bold pb-1" style="line-height: 100%;">
                        {{ fc.name }}
                      </div>
                      <div class="text-capitalize" style="line-height: 100%;font-size: 9px;">
                        {{ fc.role }}
                      </div>
                    </div>
                    <v-spacer />
                    <div class="grey--text lh-clear">
                      <em style="font-size: 10px;">
                        {{ $localDT(fc.created, 'datetime') }}
                      </em>
                    </div>
                  </v-card-text>
                  <div>
                    <div class="pt-1 pb-2 pr-2 body-1 ebright-post-content">
                      <div class="px-4 my-1 caption" v-html="$stringToEmoticon($EditorParse(fc.comment), $store.state.emoticons.emoticons)" />
                      <div class="d-flex justify-space-between px-4">
                        <v-btn
                          text
                          small
                          class="px-2"
                          @click.prevent="LoadComment(f.id, iF, fc.id, iFc), forum.data[iF].comments.map(r => { parseInt(r.id) === parseInt(fc.id) ? r.toggle = !r.toggle : r.toggle = false; return r })"
                        >
                          <v-icon class="caption mr-1 blue--text">
                            mdi-reply
                          </v-icon>
                          <span v-if="parseInt(fc.comments_total)" class="blue--text text-capitalize" style="font-size: 9px;letter-spacing: 0px;">
                            View reply ({{ fc.comments_total }})
                          </span>
                          <span v-else class="blue--text text-capitalize" style="font-size: 9px;letter-spacing: 0px;">
                            Reply
                          </span>
                        </v-btn>
                        <v-btn v-if="$store.state.user.role === 'pusat'" color="red" class="px-0" style="min-width: 20px;" outlined x-small @click.prevent="removePost(fc.id, iF, iFc)">
                          <v-icon small>
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </div>
                    </div>
                    <div v-if="fc.toggle" class="sub-comment-new px-4 mb-6">
                      <EbrightEditor :id="'new-comment-' + iF" :content="forum.data[iF].comments[iFc].new_comment" add-class="comment-field-sub" @save="forum.data[iF].comments[iFc].new_comment = $event" />
                      <v-row
                        align="center"
                        justify="end"
                        class="pt-4"
                        no-gutters
                        :class="[parseInt(fc.comments_total) ? 'pb-0' : 'pb-4']"
                      >
                        <v-btn :disabled="!forum.data[iF].comments[iFc].new_comment" :color="$vuetify.theme.dark ? 'black--text grey lighten-2' : 'white--text primary'" depressed x-small class="text-capitalize" @click.prevent="SendComment(iF, fc.id)">
                          <v-icon class="mr-1 body-1">
                            mdi-reply
                          </v-icon>
                          <span>
                            Reply
                          </span>
                        </v-btn>
                      </v-row>
                      <div v-if="fc.comments.length" class="sub-comments">
                        <div v-for="(fcs, iFcs) in fc.comments" :key="'sub-comment-' + iF + '-' + iFc + '-' + iFcs" class="sub-comments-item pb-4 px-2">
                          <div class="d-flex align-center">
                            <v-avatar size="25" class="mr-2">
                              <img
                                alt="user"
                                :src="fcs.img || '/icon.png'"
                              >
                            </v-avatar>
                            <div>
                              <div class="caption-small font-weight-bold pb-1" style="line-height: 100%;">
                                {{ fcs.name }}
                              </div>
                              <div class="text-capitalize" style="line-height: 100%;font-size: 9px;">
                                {{ fcs.role }}
                              </div>
                            </div>
                            <v-spacer />
                            <div class="grey--text">
                              <em style="font-size: 10px;">
                                {{ $localDT(fcs.created, 'datetime') }}
                              </em>
                            </div>
                            <v-btn v-if="$store.state.user.role === 'pusat'" icon small class="ml-1" @click.prevent="removePost(fcs.id, iF, iFc, iFcs)">
                              <v-icon class="body-2 red--text">
                                mdi-close
                              </v-icon>
                            </v-btn>
                          </div>
                          <div
                            class="sub-comments-item-content pt-0 pb-1 pl-8 ebright-post-content caption-small"
                            v-html="$stringToEmoticon($EditorParse(fcs.comment), $store.state.emoticons.emoticons)"
                          />
                          <v-divider v-if="fc.comments.length > 1" />
                        </div>
                      </div>
                      <v-card v-if="fc.comments.length < fc.comments_total" class="transparent my-1 no-shadow text-right pb-4 pr-2">
                        <v-btn text x-small class="text-capitalize" @click.prevent="LoadComment(f.id, iF, fc.id, iFc)">
                          load more ...
                        </v-btn>
                      </v-card>
                      <!-- <v-divider v-if="fc.comments.length" /> -->
                    </div>
                  </div>
                </v-card>
                <v-card v-if="parseInt(f.comments_total) > 10" class="accent my-1 no-shadow text-right pb-4 pr-2">
                  <v-pagination
                    v-model.number="f.page"
                    :length="Math.floor(parseInt(f.comments_total) / 10)"
                    :total-visible="7"
                    @input="LoadCommentPage($event, f)"
                  ></v-pagination>
                  <!-- <v-btn text x-small class="text-capitalize" @click.prevent="LoadComment(f.id, iF)">
                    load more ...
                  </v-btn> -->
                </v-card>
              </div>
            </v-card-text>
          </v-card>
          <div v-if="forum.total !== forum.data.length" class="text-center">
            <v-btn text small class="text-capitalize" @click.prevent="LoadForum(search, (tabActive === 'featured' ? 1 : 0), true, forum.data.length ? forum.data[forum.data.length - 1].id : 0)">
              load more
            </v-btn>
          </div>
        </v-card-text>
      </v-col>
      <!-- <v-col v-if="$store.state.screenWidth >= 960" cols="12" class="col-md-4 px-md-4">
        <v-card-title class="px-md-0">
          <div class="subtitle-1">
            Module Learning
          </div>
          <v-spacer />
          <v-btn v-if="$store.state.user.role === 'pusat'" outlined color="primary" small class="text-capitalize mr-2" @click.prevent="MODULE_OPEN()">
            <v-icon class="mr-2 body-1">
              mdi-plus
            </v-icon>
            New Module
          </v-btn>
        </v-card-title>
        <v-card-text class="px-md-0" :class="[$store.state.user.role === 'pusat' ? '' : 'pt-1']">
          <v-card class="mb-6 no-shadow">
            <v-text-field
              v-model="searchModules"
              label="Search"
              outlined
              autocomplete="off"
              block
              dense
              hide-details
            />
          </v-card>
          <v-divider class="mt-2 mb-6" />
          <v-list
            subheader
            two-line
          >
            <v-list-item
              v-for="(l, iL) in learning.data"
              :key="'learning-' + iL"
            >
              <v-list-item-avatar>
                <v-icon
                  :class="l.content_type === 'pdf' ? 'grey--text' : 'grey--text'"
                  dark
                  v-text="l.content_type === 'pdf' ? 'mdi-clipboard-text-outline' : 'mdi-video-outline'"
                ></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="l.title"></v-list-item-title>

                <v-list-item-subtitle class="caption">
                  {{ $localDT(l.created, 'datetime') }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn v-if="$store.state.user.role === 'pusat'" x-small icon @click.prevent="MODULE_OPEN(l)">
                  <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                </v-btn>
                <v-btn :x-small="$store.state.user.role === 'pusat'" icon @click.prevent="ShowLearningDialog(l)">
                  <v-icon v-if="l.content_type === 'pdf'" color="grey lighten-1">mdi-aspect-ratio</v-icon>
                  <v-icon v-else-if="l.content_type === 'video'" color="grey lighten-1">mdi-play</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-col> -->
    </v-row>
    <!-- <v-btn
      v-if="$store.state.screenWidth < 960"
      fab
      dark
      fixed
      right
      bottom
      color="primary"
      @click.prevent="dialogModuleMobile = !dialogModuleMobile"
    >
      <v-icon dark>
        mdi-school-outline
      </v-icon>
    </v-btn> -->
    <v-dialog
      v-if="$store.state.screenWidth < 960"
      v-model="dialogModuleMobile"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="rounded-0">
        <v-toolbar
          dark
          style="position: fixed;top: 0px;left: 0px;width: 100%;z-index: 1;"
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialogModuleMobile = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <div class="subtitle-1">
              Module Learning
            </div>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn v-if="$store.state.user.role === 'pusat'" depressed color="primary" small class="text-capitalize mr-2" @click.prevent="MODULE_OPEN()">
              <v-icon class="mr-2 body-1">
                mdi-plus
              </v-icon>
              New Module
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="px-4 pt-15 mt-6">
          <v-card class="mb-6 no-shadow">
            <v-text-field
              v-model="searchModules"
              label="Search"
              outlined
              autocomplete="off"
              block
              dense
              hide-details
            />
          </v-card>
          <v-divider class="mt-2 mb-6" />
          <v-list
            subheader
            two-line
          >
            <v-list-item
              v-for="(l, iL) in learning.data"
              :key="'learning-' + iL"
            >
              <v-list-item-avatar>
                <v-icon
                  :class="l.content_type === 'pdf' ? 'blue' : 'orange'"
                  dark
                  v-text="l.content_type === 'pdf' ? 'mdi-clipboard-text' : 'mdi-video'"
                ></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="l.title"></v-list-item-title>

                <v-list-item-subtitle class="caption">
                  {{ $localDT(l.created, 'datetime') }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn v-if="$store.state.user.role === 'pusat'" x-small icon @click.prevent="MODULE_OPEN(l)">
                  <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                </v-btn>
                <v-btn :x-small="$store.state.user.role === 'pusat'" icon @click.prevent="ShowLearningDialog(l)">
                  <v-icon v-if="l.content_type === 'pdf'" color="grey lighten-1">mdi-aspect-ratio</v-icon>
                  <v-icon v-else-if="l.content_type === 'video'" color="grey lighten-1">mdi-play</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="learningDialog.show"
      persistent
      :scrollable="learningDialog.data ? learningDialog.data.content_type === 'pdf' : false"
      max-width="900"
    >
      <v-card v-if="learningDialog.data">
        <v-card-title class="subtitle-1 pt-3 pb-0">
          <v-spacer />{{ learningDialog.data.title }}<v-spacer />
        </v-card-title>
        <v-card-text v-if="learningDialog.data.content_type === 'video'" class="pb-0">
          <div class="player-container">
            <vue-core-video-player v-if="learningDialog.show" @play="''" :src="learningDialog.data.content" />
          </div>
        </v-card-text>
        <v-card-text v-if="learningDialog.data.content_type === 'pdf'" class="pb-0 px-0 px-md-6" style="min-height: calc(100vh - 177px);">
          <PdfViewer v-if="learningDialog.show && learningDialog.data.content_type === 'pdf'" :src="learningDialog.data.content" />
        </v-card-text>
        <v-card-actions class="px-6">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            small
            block
            class="text-capitalize font-weight-normal"
            @click="learningDialog.show = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="newModule.show"
      max-width="450"
      scrollable
    >
      <v-card v-if="newModule.data">
        <v-card-title
          class="primary white--text py-3 subtitle-1"
        >
          {{ parseInt(newModule.data.id) ? 'Update Module' : 'Upload New Module' }}
        </v-card-title>
        <v-card-text>
          <div class="px-2 pt-8 pb-0">
            <v-text-field
              v-model="newModule.data.title"
              label="Module Title"
              outlined
              dense
              hide-details
              autocomplete="off"
              class="mb-4"
            />
            <v-select
              v-model="newModule.data.content_type"
              :items="[
                { value: 'pdf', name: 'PDF' },
                { value: 'video', name: 'Video' }
              ]"
              item-text="name"
              item-value="value"
              label="Content Type"
              outlined
              dense
              hide-details
              autocomplete="off"
              class="mb-4"
            />
            <v-btn block class="mt-2 mb-4" outlined @click.prevent="UPLOAD_FILE(newModule.data.content_type === 'video' ? '.mp4' : '.pdf')">
              <span v-if="parseInt(newModule.data.id)">
                CHANGE FILE
              </span>
              <span v-else>
                UPLOAD {{ newModule.data.content_type }} FILE
              </span>
            </v-btn>
            <v-row no-gutters class="mt-2 mb-6">
              <v-col cols="12" class="col-md-6 py-2">
                <v-checkbox
                  v-model="newModule.data.for_distributor"
                  label="Distributor"
                  class="mt-0"
                  hide-details
                />
              </v-col>
              <v-col cols="12" class="col-md-6 py-2">
                <v-checkbox
                  v-model="newModule.data.for_agen"
                  label="Agen"
                  class="mt-0"
                  hide-details
                />
              </v-col>
              <v-col cols="12" class="col-md-6 py-2">
                <v-checkbox
                  v-model="newModule.data.for_reseller"
                  label="Reseller"
                  class="mt-0"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-select
              v-model.number="newModule.data.status"
              :items="[
                { value: 0, name: 'Not active' },
                { value: 1, name: 'Active' }
              ]"
              item-text="name"
              item-value="value"
              label="Module Status"
              outlined
              dense
              autocomplete="off"
              hide-details
            />
          </div>
        </v-card-text>
        <v-card-actions class="justify-end px-8 pb-4">
          <v-btn
            outlined
            color="red"
            small
            @click="newModule.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn
            depressed
            color="primary"
            small
            @click="MODULE_PROCESS()"
          >
            {{ newModule.data.id ? 'Update' : 'Create' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="loadingUpload"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Uploading, please wait ...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
let searchTimeout = null
let searchModulesTimeout = null
export default {
  data: () => ({
    loading: false,
    dialogModuleMobile: false,
    learningDialog: {
      show: false,
      data: null
    },
    tabActive: 'all',
    search: '',
    searchModules: '',
    newPost: {
      show: false,
      title: '',
      cover: [],
      text: ''
    },
    newModule: {
      show: false,
      data: null
    },
    forum: {
      total: 0,
      data: []
    },
    learning: {
      total: 0,
      data: []
    },
    loadingUpload: false
    // videoSource: [
    //   {
    //     src: 'https://media.vued.vanthink.cn/sparkle_your_name_am360p.mp4',
    //     resolution: '360p'
    //   }, {
    //     src: 'https://media.vued.vanthink.cn/sparkle_your_name_am720p.mp4',
    //     resolution: '720p'
    //   }, {
    //     src: 'https://media.vued.vanthink.cn/y2mate.com%20-%20sparkle_your_name_amv_K_7To_y9IAM_1080p.mp4',
    //     resolution: '1080p'
    //   }
    // ]
  }),
  watch: {
    search: function (q) {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = setTimeout(() => {
        this.LoadForum(q)
        clearTimeout(searchTimeout)
      }, 300)
    },
    searchModules: function (q) {
      if (searchModulesTimeout) {
        clearTimeout(searchModulesTimeout)
      }
      searchModulesTimeout = setTimeout(() => {
        this.LoadLearning(q)
        clearTimeout(searchModulesTimeout)
      }, 300)
    }
  },
  mounted () {
    this.LoadForum('')
    // this.LoadLearning()
  },
  methods: {
    Observe (e, data) {
      if (e && data) {
        const el = data.target
        if (el) {
          const id = el.getAttribute('observe')
          const hasread = el.getAttribute('hasread')
          if (parseInt(id) && !parseInt(hasread)) {
            // this.$store.dispatch('MEDIA_READ', {
            //   id: null,
            //   content_id: parseInt(id)
            // }).then((res) => {
            //   if (res.status) {
            //     el.setAttribute('hasread', 1)
            //     this.$store.commit('SET_MEDIA_FORUM_READ', parseInt(this.$store.state.mediaForumRead) > 0 ? (parseInt(this.$store.state.mediaForumRead) - 1) : 0)
            //   }
            // })
          }
        }
      }
    },
    SaveNewPost (r) {
      this.newPost.text = r
    },
    ShowLearningDialog (d) {
      this.learningDialog.data = Object.assign({}, d)
      this.learningDialog.show = true
    },
    SendComment (index, commentID) {
      const media = Object.assign({}, this.forum.data[index])
      if (!commentID && !media.new_comment) {
        return false
      } else {
        const commentParams = {
          media: media.id,
          comment: media.new_comment,
          comment_lead: 0
        }
        const mediaCommentIndex = this.forum.data[index].comments.findIndex(r => parseInt(r.id) === parseInt(commentID))
        if (parseInt(commentID) && mediaCommentIndex > -1) {
          commentParams.comment = this.forum.data[index].comments[mediaCommentIndex].new_comment
          commentParams.comment_lead = commentID
          this.forum.data[index].comments[mediaCommentIndex].new_comment = ''
        } else {
          this.forum.data[index].new_comment = ''
        }
        if (!this.$StipHTML(commentParams.comment)) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Comment is empty' })
          return false
        }
        this.$store.dispatch('MEDIA_COMMENT_ADD', commentParams).then((res) => {
          if (res.status) {
            if (parseInt(commentID)) {
              this.forum.data[index].comments[mediaCommentIndex].comments.unshift(Object.assign({ name: this.$store.state.user.name, img: this.$store.state.user.img }, res.data.data))
              this.forum.data[index].comments[mediaCommentIndex].comments_total += 1
            } else {
              this.forum.data[index].comments.unshift(Object.assign({ name: this.$store.state.user.name, img: this.$store.state.user.img }, res.data.data))
              this.forum.data[index].comments_total += 1
            }
          } else {
            this.forum.data[index].new_comment = media.new_comment
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          }
        })
      }
    },
    async removePost (id, mediaIndex, commentIndex, subcommentIndex) {
      let type = ''
      if (subcommentIndex === 0 || subcommentIndex > 0) {
        await this.$store.dispatch('POPUP', {
          t: this,
          show: true,
          type: 'confirm',
          title: 'Confirm',
          text: 'Remove this reply comment?'
        }).then((res) => {
          if (res.result) {
            type = 'comment'
            this.forum.data[mediaIndex].comments[commentIndex].comments.splice(subcommentIndex, 1)
            this.forum.data[mediaIndex].comments[commentIndex].comments_total = parseInt(this.forum.data[mediaIndex].comments[commentIndex].comments_total) - 1
          }
        })
      } else if (commentIndex === 0 || commentIndex > 0) {
        await this.$store.dispatch('POPUP', {
          t: this,
          show: true,
          type: 'confirm',
          title: 'Confirm',
          text: 'Remove this comment?'
        }).then((res) => {
          if (res.result) {
            type = 'comment'
            this.forum.data[mediaIndex].comments.splice(commentIndex, 1)
            this.forum.data[mediaIndex].comments_total = parseInt(this.forum.data[mediaIndex].comments_total) - 1
          }
        })
      } else if (mediaIndex === 0 || mediaIndex > 0) {
        await this.$store.dispatch('POPUP', {
          t: this,
          show: true,
          type: 'confirm',
          title: 'Confirm',
          text: 'Archive this post?'
        }).then((res) => {
          if (res.result) {
            type = 'media'
            this.forum.data.splice(mediaIndex, 1)
            this.forum.total = parseInt(this.forum.total) - 1
          }
        })
      }
      if (type === 'media') {
        this.$store.dispatch('MEDIA_UPDATE', {
          id: id,
          status: 99
        }).then((res) => {
          // console.log(res)
        })
      } else if (type === 'comment') {
        this.$store.dispatch('MEDIA_COMMENT_UPDATE', {
          id: id,
          status: 99
        }).then((res) => {
          // console.log(res)
        })
      }
    },
    async unremovePost (id, mediaIndex, commentIndex, subcommentIndex) {
      let type = ''
      if (subcommentIndex === 0 || subcommentIndex > 0) {
        await this.$store.dispatch('POPUP', {
          t: this,
          show: true,
          type: 'confirm',
          title: 'Confirm',
          text: 'Unarchive this comment?'
        }).then((res) => {
          if (res.result) {
            type = 'comment'
            this.forum.data[mediaIndex].comments[commentIndex].comments.splice(subcommentIndex, 1)
            this.forum.data[mediaIndex].comments[commentIndex].comments_total = parseInt(this.forum.data[mediaIndex].comments[commentIndex].comments_total) - 1
          }
        })
      } else if (commentIndex === 0 || commentIndex > 0) {
        await this.$store.dispatch('POPUP', {
          t: this,
          show: true,
          type: 'confirm',
          title: 'Confirm',
          text: 'Unarchive this comment?'
        }).then((res) => {
          if (res.result) {
            type = 'comment'
            this.forum.data[mediaIndex].comments.splice(commentIndex, 1)
            this.forum.data[mediaIndex].comments_total = parseInt(this.forum.data[mediaIndex].comments_total) - 1
          }
        })
      } else if (mediaIndex === 0 || mediaIndex > 0) {
        await this.$store.dispatch('POPUP', {
          t: this,
          show: true,
          type: 'confirm',
          title: 'Confirm',
          text: 'Unarchive this post?'
        }).then((res) => {
          if (res.result) {
            type = 'media'
            this.forum.data.splice(mediaIndex, 1)
            this.forum.total = parseInt(this.forum.total) - 1
          }
        })
      }
      if (type === 'media') {
        this.$store.dispatch('MEDIA_UPDATE', {
          id: id,
          status: 1
        }).then((res) => {
          // console.log(res)
        })
      } else if (type === 'comment') {
        this.$store.dispatch('MEDIA_COMMENT_UPDATE', {
          id: id,
          status: 1
        }).then((res) => {
          // console.log(res)
        })
      }
    },
    async DeletePost (id, mediaIndex) {
      await this.$store.dispatch('POPUP', {
        t: this,
        show: true,
        type: 'confirm',
        title: 'Confirm',
        text: 'Remove this post permanently?'
      }).then((res) => {
        if (res.result) {
          this.$store.dispatch('MEDIA_REMOVE', {
            id: id,
            status: 99
          }).then((res) => {
            if (res.status) {
              this.forum.total = parseInt(this.forum.total) - 1
              this.forum.data.splice(mediaIndex, 1)
            } else {
              this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
            }
          })
        }
      })
    },
    MODULE_OPEN (m) {
      if (m) {
        m.for_distributor = parseInt(m.for_distributor) || false
        m.for_agen = parseInt(m.for_agen) || false
        m.for_reseller = parseInt(m.for_reseller) || false
        m.for_customer = parseInt(m.for_customer) || false
        this.newModule.data = Object.assign({}, m)
      } else {
        this.newModule.data = {
          id: null,
          title: '',
          type: 'learning',
          content_type: 'pdf',
          content: '',
          for_distributor: true,
          for_agen: true,
          for_reseller: true,
          for_customer: false,
          status: 1
        }
      }
      this.newModule.show = true
    },
    MODULE_PROCESS () {
      const newData = Object.assign({}, this.newModule.data)
      if (!newData) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Invalid input' })
        return false
      } else {
        if (!newData.title) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Please input title' })
          return false
        }
        if (!newData.content) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Please upload file' })
          return false
        }
        newData.for_distributor = newData.for_distributor ? 1 : 0
        newData.for_agen = newData.for_agen ? 1 : 0
        newData.for_reseller = newData.for_reseller ? 1 : 0
        newData.for_customer = newData.for_customer ? 1 : 0
        if (parseInt(newData.id)) {
          this.$store.dispatch('MEDIA_UPDATE', newData).then((res) => {
            if (res.status) {
              this.newModule.show = false
              this.LoadLearning(this.searchModules)
              this.$store.commit('SNACKBAR', { show: true, text: 'Module updated' })
            } else {
              this.$store.commit('SNACKBAR', { show: true, text: 'Ops, something went wrong' })
            }
          })
        } else {
          this.$store.dispatch('MEDIA_ADD', newData).then((res) => {
            if (res.status) {
              this.newModule.show = false
              this.LoadLearning()
              this.$store.commit('SNACKBAR', { show: true, text: 'New module created' })
            } else {
              this.$store.commit('SNACKBAR', { show: true, text: 'Ops, something went wrong' })
            }
          })
        }
      }
    },
    CreateNewPost () {
      if (!this.newPost.title) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please input title' })
        return false
      }
      if (!this.$StipHTML(this.newPost.text)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please write something' })
        return false
      }
      this.$store.dispatch('MEDIA_ADD', {
        type: 'forum',
        title: this.newPost.title,
        content: this.newPost.text,
        cover: this.$JsonToStr(this.newPost.cover || []) || '[]'
      }).then((res) => {
        if (res.status) {
          this.tabActive = 'all'
          this.newPost.show = false
          this.newPost.title = ''
          this.newPost.cover = []
          this.newPost.text = ''
          this.search = ''
          this.LoadForum()
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
        }
      })
    },
    UpdatePost (f) {
      if (!f.content_edit.show) {
        const findPost = this.forum.data.findIndex(r => parseInt(r.id) === parseInt(f.id))
        this.forum.data[findPost].content_edit.show = true
        return false
      }
      if (!f.content_edit.title) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please input title' })
        return false
      }
      if (!this.$StipHTML(f.content_edit.content)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please write something' })
        return false
      }
      // if (f.content_edit.content === f.content && this.$JsonToStr(f.content_edit.cover) === f.cover) {
      //   const findPost = this.forum.data.findIndex(r => parseInt(r.id) === parseInt(f.id))
      //   this.forum.data[findPost].content_edit.show = false
      //   return false
      // }
      this.$store.dispatch('MEDIA_UPDATE', {
        type: 'forum',
        id: f.id,
        title: f.content_edit.title,
        cover: this.$JsonToStr(f.content_edit.cover || []) || '[]',
        content: f.content_edit.content
      }).then((res) => {
        if (res.status) {
          const findPost = this.forum.data.findIndex(r => parseInt(r.id) === parseInt(f.id))
          if (findPost > -1) {
            this.forum.data[findPost].title = f.content_edit.title
            this.forum.data[findPost].cover = this.$JsonToStr(f.content_edit.cover || []) || '[]'
            this.forum.data[findPost].content_edit.cover = f.content_edit.cover || []
            this.forum.data[findPost].content = f.content_edit.content
            this.forum.data[findPost].content_edit.show = false
            this.$store.commit('SNACKBAR', { show: true, text: 'Updated' })
          }
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
        }
      })
    },
    SetFeatured (f) {
      f.featured = parseInt(f.featured) ? 0 : 1
      this.$store.dispatch('MEDIA_UPDATE', f)
    },
    ShowMedia (type) {
      this.tabActive = type
      this.forum.data = []
      this.forum.total = 0
      if (type === 'featured') {
        this.LoadForum(this.search, 1)
      } else if (type === 'archive') {
        this.LoadForum(this.search, '', null, 0, true)
      } else {
        this.LoadForum(this.search, '')
      }
    },
    LoadForum (q, featured, loadMore, lastID, archived) {
      this.loading = true
      archived = archived ? '&status=99' : '&status=1'
      this.$store.dispatch('MEDIA_FORUM_GET', '?q=' + (q || '') + '&featured=' + (parseInt(featured) || '') + '&last_id=' + (parseInt(lastID || '') || '') + archived).then((res) => {
        if (res.status) {
          this.forum.total = parseInt(res.data.data.total) || 0
          if (loadMore) {
            this.forum.data = this.forum.data.concat((res.data.data.data || []).map((r) => {
              r.comments = []
              r.comments_total = parseInt(r.comments_total) || 0
              r.new_comment_show = false
              r.new_comment = ''
              r.page = 1
              r.content_edit = {
                show: false,
                title: r.title || '',
                cover: this.$StrToJson(r.cover || '[]') || [],
                content: r.content || ''
              }
              return r
            }))
          } else {
            this.forum.data = (res.data.data.data || []).map((r) => {
              r.comments = []
              r.comments_total = parseInt(r.comments_total) || 0
              r.new_comment_show = false
              r.new_comment = ''
              r.page = 1
              r.content_edit = {
                show: false,
                title: r.title || '',
                cover: this.$StrToJson(r.cover || '[]') || [],
                content: r.content || ''
              }
              return r
            })
          }
        }
        this.loading = false
      })
    },
    LoadLearning (q) {
      this.$store.dispatch('MEDIA_LEARNING_GET', '?q=' + (q || '')).then((res) => {
        if (res.status) {
          this.learning.total = parseInt(res.data.data.total) || 0
          this.learning.data = res.data.data.data || []
        }
      })
    },
    LoadComment (mediaID, mediaIndex, commentID, commentIndex) {
      if (commentID && (parseInt(commentIndex) === 0 || parseInt(commentIndex) > 0)) {
        const comments = this.forum.data[mediaIndex].comments[commentIndex].comments
        this.$store.dispatch('MEDIA_COMMENT_GET', {
          media: mediaID,
          q: '?last_id=' + (comments.length ? comments[comments.length - 1].id : 0) + '&comment_lead=' + commentID
        }).then((res) => {
          if (res.status) {
            const mediaIndex = this.forum.data.findIndex(r => parseInt(r.id) === parseInt(mediaID))
            const index = this.forum.data[mediaIndex].comments.findIndex(r => parseInt(r.id) === parseInt(commentID))
            if (index > -1) {
              this.forum.data[mediaIndex].comments[index].comments = this.forum.data[mediaIndex].comments[index].comments.concat(res.data.data || []).map((r) => {
                r.comments = []
                r.comments_total = parseInt(r.comments_total) || 0
                r.new_comment_show = false
                r.new_comment = ''
                r.toggle = false
                return r
              })
            }
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          }
        })
      } else {
        const comments = this.forum.data[mediaIndex].comments
        this.$store.dispatch('MEDIA_COMMENT_GET', {
          media: mediaID,
          q: '?last_id=' + (comments.length ? comments[comments.length - 1].id : 0)
        }).then((res) => {
          if (res.status) {
            const index = this.forum.data.findIndex(r => parseInt(r.id) === parseInt(mediaID))
            if (index > -1) {
              this.forum.data[index].comments = this.forum.data[index].comments.concat(res.data.data || []).map((r) => {
                r.comments = []
                r.comments_total = parseInt(r.comments_total) || 0
                r.new_comment_show = false
                r.new_comment = ''
                r.toggle = false
                return r
              })
            }
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          }
        })
      }
    },
    LoadCommentPage (v, f) {
      this.$store.dispatch('MEDIA_COMMENT_GET', {
        media: parseInt(f.id),
        q: '?page=' + (v) + '&limit=10'
      }).then((res) => {
        if (res.status) {
          const index = this.forum.data.findIndex(r => parseInt(r.id) === parseInt(f.id))
          if (index > -1) {
            this.forum.data[index].comments = (res.data.data || []).map((r) => {
              r.comments = []
              r.comments_total = parseInt(r.comments_total) || 0
              r.new_comment_show = false
              r.new_comment = ''
              r.toggle = false
              return r
            })
          }
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
        }
      })
    },
    UPLOAD_FILE (type, index, newPost) {
      const f = document.getElementById('fileUploader')
      if (f) {
        f.remove()
      }
      const $ = this
      const el = document.createElement('input')
      el.setAttribute('id', 'fileUploader')
      el.setAttribute('type', 'file')
      el.setAttribute('class', 'file-uploader')
      el.setAttribute('accept', type === 'attach' ? '*' : type || '.pdf')
      el.addEventListener('change', function (e) {
        $.UPLOAD_HANDLER(e, index, newPost)
      })
      document.body.appendChild(el)
      el.click()
    },
    async UPLOAD_HANDLER (event, index, newPost) {
      /* eslint-disable */
      const $ = this
      $.loadingUpload = true
      await $.$imgtoBase64(event, async function (res) {
        if (res.status) {
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data,
            source: newPost ? 'post-cover' : ''
          }).then((resUpload) => {
            $.loadingUpload = false
            if (resUpload) {
              if (newPost === 'post') {
                $.forum.data[index].content_edit.cover.push(resUpload.data.data.secure_url)
                $.forum.data[index].cover = $.$JsonToStr($.forum.data[index].content_edit.cover || [])
              } else if (newPost) {
                $.newPost.cover.push(resUpload.data.data.secure_url)
              } else {
                $.newModule.data.content = resUpload.data.data.secure_url || ''
              }
              $.$store.commit('SNACKBAR', { show: true, text: 'File uploaded' })
            } else {
              $.$store.commit('SNACKBAR', { show: true, text: 'Failed upload' })
            }
          })
        } else {
          $.loadingUpload = true
          res.data === 'file_size'
            ? $.$store.commit('SNACKBAR', { show: true, message: 'Ops, file image is too large. Max size (images < 5Mb)' })
            : ''
            // : res.data === 'canceled'
              // ? console.log('Upload canceled.')
              // : console.log(res.data)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.player-container {
  .vcp-container {
    video {
      display: block;
    }
    .btn-control {
      &.btn-play,
      &.btn-pause {
        >svg {
          >path {
            fill: #c6893f!important;
          }
          >g {
            >rect {
              fill: #c6893f!important;
            }
          }
        }
      }
    }
    .vcp-progress-played {
      background-color: #c6893f!important;
    }
  }
}
.ce-paragraph {
  line-height: 1.2em!important;
}
.ce-header {
  padding: .7em 0!important;
  line-height: 1.1em!important;
}
</style>
